const getTelLinkFromPhone = (phone) => "tel:+55" + phone.replace(/[^0-9]/g, "");

const getWhatsappLinkFromPhone = (phone) =>
  "https://wa.me/55" + phone.replace(/[^0-9]/g, "");

/**
 * Obter código do vído do youtube por sua URL.
 *
 * @param {string} url
 */
const getYoutubeCode = (url) => {
  url = new URL(url);

  let code;
  switch (url.host) {
    case "www.youtube.com":
      code = url.searchParams.get("v");
      break;
    case "youtu.be":
      code = url.pathname.replace(/\/(.+)\/?.*/, "$1");
      break;
  }
  return code;
};

/**
 * Formatar string para URL.
 */
const formatToURL = (str) => {
  return str
    .normalize("NFD")
    .replace(/\s+/g, "-")
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-])/g, "")
    .toLocaleLowerCase();
};

const phoneTest = (phone) => {
  var regex = new RegExp("^\\([0-9]{2}\\) [0-9]{4}-[0-9]{4}$");
  return regex.test(phone);
};

const formatPrice = (str) => {
  str = str.replace(/\D/g, "");
  str = (str / 100).toFixed(2) + "";
  str = str.replace(".", ",");
  str = str.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  str = str.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return str;
};

export {
  getTelLinkFromPhone,
  getWhatsappLinkFromPhone,
  getYoutubeCode,
  formatToURL,
  phoneTest,
  formatPrice,
};
