var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"href":_vm.item.link,"target":"_blank","color":"transparent","flat":"","tile":""}},[_c('img',{staticClass:"d-flex mx-auto",attrs:{"loading":"lazy","src":_vm.item.img,"alt":_vm.item.title,"width":_vm.item.imgWidth}}),(_vm.item.title)?_c('div',{staticClass:"text-body-1 font-weight-bold text-center mt-4"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e(),(_vm.item.address)?_c('div',{staticClass:"mt-2"},_vm._l((_vm.item.address),function(text,key){return _c('div',{key:key,staticClass:"\n        text-body-2\n        font-weight-medium\n        grey--text\n        text--darken-2 text-center\n      "},[_vm._v(" "+_vm._s(text)+" ")])}),0):_vm._e(),(_vm.item.tel)?_c('div',{staticClass:"mt-2 d-flex justify-center"},_vm._l((_vm.item.tel),function(ref,key){
var num = ref.num;
var link = ref.link;
return _c('div',{key:key},[(key > 0)?_c('div',{staticClass:"mx-2 d-inline-flex"},[_vm._v("|")]):_vm._e(),_c('a',{staticClass:"\n          text-body-2\n          font-weight-bold\n          grey--text\n          text--darken-4 text-decoration-none\n        ",attrs:{"href":link}},[_vm._v(" "+_vm._s(num)+" ")])])}),0):_vm._e(),(_vm.item.icons)?_c('div',{staticClass:"mt-2 d-flex align-center justify-center"},_vm._l((_vm.item.icons),function(ref,key){
var icon = ref.icon;
var src = ref.src;
var link = ref.link;
var color = ref.color;
return _c('div',{key:key + _vm.item.tel.length},[_c('a',{staticClass:"d-block text-body-2 text-decoration-none font-weight-bold mx-4",attrs:{"href":link,"target":"_blank"}},[(icon)?_c('v-icon',{attrs:{"color":color,"size":"41.3"}},[_vm._v(" "+_vm._s(icon)+" ")]):_c('img',{staticClass:"d-block",attrs:{"src":src,"width":"36px"}})],1)])}),0):_vm._e(),(!_vm.item.link)?_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-caption grey--text text--darken-4 font-weight-bold mt-2",attrs:{"color":"yellow"}},[_vm._v(" EM BREVE... ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }